import {
    Box,
    Button,
    Collapse,
    Flex,
    Icon,
    useToast,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { MdOutlineErrorOutline } from 'react-icons/md';

const Error = ({ open }) => {
    const toast = useToast();

    useEffect(() => {
        if (open) {
            toast({
                status: 'error',
                title: 'Error Fetching Data',
                description: 'There was an error fetching the requested data.',
                position: 'top-right',
            });
        }
    }, [open]);

    return null;
};

export default Error;

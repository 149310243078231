import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    Image as UIImage,
    useDisclosure,
    Flex,
} from '@chakra-ui/react';

import { Image, Transformation } from 'cloudinary-react';

const ImagePreview = ({ url, children, public_id }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <React.Fragment>
            <Flex onClick={onOpen} curosr='pointer'>
                {children}
            </Flex>
            <Modal
                isCentered
                size='full'
                scrollBehavior='inside'
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />

                <ModalContent>
                    <ModalCloseButton />
                    <Flex
                        w='100%'
                        h='100%'
                        p='40px'
                        overflow='hidden'
                        align='center'
                        justify='center'
                    >
                        {public_id ? (
                            <Image publicId={public_id} secure='true'>
                                <Transformation
                                    width='1000'
                                    crop='thumb'
                                    fetchFormat='auto'
                                />
                            </Image>
                        ) : (
                            <UIImage
                                src={url}
                                boxSize='100%'
                                objectFit='contain'
                            />
                        )}
                    </Flex>
                </ModalContent>
            </Modal>
        </React.Fragment>
    );
};

export default ImagePreview;
